(function(window, document, $, undefined) {
    (window.swipeList = {
        init: function() {
            var self = this;
            $(function() {
                self.swipeTab();
            });
        },
        swipeTab: function() {
            var $swipeTabList = $('.js-swipe-tab-list');
            var index = $('.swipe-list-tab-item.current').index('.swipe-list-tab-item');
            var winWidth = $(window).width();
            var tabWidth = $swipeTabList.width();
            var tabElemWidth = 100;
            var timesFullMove = Math.floor((tabWidth - winWidth) / tabElemWidth);
            var flgWideScreen = false;
            var tabMove=0;

            // タブ部分のスワイプ設定
            var scrollObj = new IScroll(
                '.js-swipe-tab',
                { scrollX: true, scrollY: false, mouseWheel: true, click: true, disablePointer: true }
            );

            // orientationchange時の処理
            $(window).on('orientationchange', function() {
                winWidth = $(window).width();
                setFlgWideScreen();
                timesFullMove = Math.floor((tabWidth - winWidth) / tabElemWidth);

                if(flgWideScreen) {
                    setTimeout(function(){
                        resetTab();
                    }, 10);
                } else {
                    setTab();
                }
            });

            // カレントのカテゴリによって、タブの位置を調整
            function setTab() {
                if(index <= timesFullMove) {
                    if(index === 0) {
                        tabMove = 0;
                    } else {
                        tabMove = (index - 1) * -tabElemWidth;
                    }
                } else {
                    tabMove = winWidth - tabWidth;
                }
                // スマホ以外とタブ非選択状態に対応
                if(flgWideScreen || index < 0) {
                    tabMove = 0;
                }
                scrollObj.scrollTo(tabMove, 0);
            }

            // タブの位置リセット
            function resetTab() {
                scrollObj.scrollTo(0, 0);
            }

            // 画面幅がタブ幅よりも大きいかの判定
            function setFlgWideScreen() {
                if(winWidth >= tabWidth) {
                    flgWideScreen = true;
                } else {
                    flgWideScreen = false;
                }
            }

            setFlgWideScreen();
            setTab();
        }
    }).init();
})(window, document, jQuery);
