(function($) {
    // 読み込んだら開始
    $(function() {
        // アコーディオン
        var accordion = $(".js-toggle-wrap");
        accordion.each(function () {
            var noTargetAccordion = $(this).siblings(accordion);
            $(this).find(".js-toggle-header").click(function() {
                $(this).next(".js-toggle-content").slideToggle();
                $(this).toggleClass("is-show");
                noTargetAccordion.find(".js-toggle-content").slideUp();
                noTargetAccordion.find(".js-toggle-header").removeClass("is-show");
            });
            $(this).find(".js-toggle-close").click(function() {
                var targetContentWrap = $(this).parent(".js-toggle-content");
                $(targetContentWrap).slideToggle();
                $(targetContentWrap).prev(".js-toggle-header").toggleClass("is-show");
            });
        });
    
    });
})(jQuery);