/*--------------------------------------
    スクロール時に右サイドを追従させるJS
    ※様々な要素のレスポンシブ対応版

    仕様
    ・スクロール時にsideをfixedする。
    ・指定サイズ以下では横スクロールによるfixedの不具合が発生するためfixedを解除する。
    ・fixed実行フラグ：左側（コンテンツ）が右側（side）より短い場合はfixedしない。
--------------------------------------*/

$(function() {

    // 定数
    var layoutContent = $('.content'),  // レイアウト main,sideを含むオブジェクト
        layoutMainArea = $('.main'),  // レイアウト 左側メインコンテンツ部分オブジェクト
        layoutSideArea = $('.sub-cont-wrap'),  // レイアウト 右側サイドコンテンツ部分オブジェクト
        layoutSideInner = $('.sub-cont-wrap-inner'),    // レイアウト 右側サイドコンテンツ、インナー要素部分オブジェクト
        layoutHeader = $('header.header'),  // ヘッダー部分オブジェクト
        layoutNav = $('nav.swipe-list-tab'),  // ナビゲーション部分オブジェクト
        layoutNavBottom = $('.js-top'),  // ナビゲーション下部部分オブジェクト　カルーセル等、ページ毎に異なる要素
        layoutMainAreaH = 0,
        layoutSideAreaH = layoutSideArea.outerHeight({margin: true}),
        isHorizontal = false,   // 横幅処理実行フラグ
        nowHeight = 0,  // 現在のウインドウの高さ
        nowWdScrollTop = 0, // windowのtop位置
        nowWdScrollBottom = 0,  // windowのbottom位置
        nowHeaderAndNavAndSideHeight = 0,
        nowHeaderAndNavAndContentHeight = 0;
        wd = $(window);


    // fixed判定（true=fixed, false=no fixed）
    var isFixed=false;
    // ウィンドウ横幅の初期値
    var nowWidth = 0;
    var targetWidth = 0;
    // windowのtop位置
    var nowWdScrollTop = 0;
    // windowのbottom位置 for side
    var nowHeaderAndNavAndSideHeight = 0;
    // 表示位置の調整関数
    var setLayoutSide = setLayoutSideNormal;
    // 初期処理
    init();


    /**
     * 初期処理
     */
    function init() {
        // 各種ウインドウの関連サイズを取得
        getWindowSetting();
        // 初回はスクロール時の処理を実行し、表示を最適化する
        scrollVerticalProc();
        // 垂直スクロール時の処理を、スクロールイベントで実行
        wd.scroll(function () {
            scrollVerticalProc();
        });
        // 水平スクロール時の処理を、横スクロールイベントで実行
        wd.resize(function () {
            // サイドメニュー固定化処理：水平方向
            scrollHorizontalProc();
        });
    }

    /**
     * サイドメニュー固定化処理：水平方向
     */
    function scrollHorizontalProc() {
        // 各種ウインドウの関連サイズを取得
        getWindowSetting();
        // 横幅変更時の共通処理
        setWidthProc();
        scrollVerticalProc();
    }

    /**
     * サイドメニュー固定化処理：垂直方向
     */
    function scrollVerticalProc() {
        // 各種ウインドウの関連サイズを取得
        getWindowSetting();
        // 横幅変更時の共通処理
        setWidthProc();
        // 表示位置を調整
        setLayoutSide();
    }

    /**
     * 表示位置を調整（通常）
     */
    function setLayoutSideNormal() {
        if(isHorizontal) {
            if(nowWdScrollBottom > nowHeaderAndNavAndSideHeight &&
                nowWdScrollBottom <= nowHeaderAndNavAndContentHeight) {
                // 高さはfixed以外ではautoに戻す
                layoutSideArea.css({'height': 'auto'});
                layoutSideInner.css({'position': 'fixed'});
                layoutSideInner.css('top', '');
            } else if(nowWdScrollBottom > nowHeaderAndNavAndContentHeight) {
                var mainH = layoutMainArea.height();
                // サイドを一番下へabsolute移動するために高さを調整する※左側のコンテンツがsideより大きいことが前提。
                layoutSideArea.css({'height': mainH+'px'});
                layoutSideInner.css({'position': 'absolute', 'bottom': '0px'});
                layoutSideInner.css('top', '');
            } else {
                // 高さはfixed以外ではautoに戻す
                layoutSideArea.css({'height': 'auto'});
                layoutSideInner.css({'position': 'static', 'bottom': '0px'});
                layoutSideInner.css('top', '');
            }
        } else {
            // 高さはfixed以外ではautoに戻す
            layoutSideArea.css({'height': 'auto'});
            layoutSideInner.css({'position': 'static', 'bottom': '0px'});
            layoutSideInner.css('top', '');
        }
    }

    /**
     * 横幅変更時の共通処理
     */
    function setWidthProc() {
        if (nowWidth > targetWidth) {
            // sideのfixed処理
            isFixed = true;
            // スマホサイズ以外はside表示
            layoutSideArea.show();
        } else if (nowWidth <= 1025) {
            // スマホサイズの場合非表示
            layoutSideArea.hide();
        } else {
            // sideのfixed無効化処理
            isFixed = false;
            // スマホサイズ以外はside表示
            layoutSideArea.show();
        }
    }

    /**
     * 各種ウインドウの関連サイズを取得
     */
    function getWindowSetting() {
        nowWidth = wd.width();
        nowHeight = wd.height();
        targetWidth = layoutContent.width();
        // コンテンツの高さを取得。無限スクロール等で高さが変更されることを考慮
        layoutMainAreaH = layoutMainArea.outerHeight({margin: true});
        // windowのtop位置
        nowWdScrollTop = wd.scrollTop();
        // windowのbottom位置
        nowWdScrollBottom = nowWdScrollTop+nowHeight;
        // サイドバーの高さを取得 ※jsで処理されるものがあったりするので随時高さを取得、fixedの場合は高さを取得できないので取得しない。
        if(!isFixed) {
            layoutSideAreaH = layoutSideArea.outerHeight({margin: true});
        }
        // 水平横幅変更時に処理をするかどうか
        if (nowWidth > targetWidth) {
            isHorizontal = true;
            if(layoutMainAreaH <= layoutSideAreaH) {
                isHorizontal = false;

            }
        } else {
            isHorizontal = false;
        }

        nowHeaderAndNavAndSideHeight = 
            layoutHeader.outerHeight({margin: true})+
            layoutNav.outerHeight({margin: true})+
            layoutNavBottom.outerHeight({margin: true,padding: true})+
            layoutSideAreaH;
        nowHeaderAndNavAndContentHeight = 
            layoutHeader.outerHeight({margin: true})+
            layoutNav.outerHeight({margin: true})+
            layoutNavBottom.outerHeight({margin: true,padding: true})+
            layoutMainArea.outerHeight({margin: true});
    }

});


    /**
     * debug出力
     *  debug('a');
     *  debug('b');
     */
    function debug(inString) {
        if(($('body .debug').size() == 0)) {
            $('body').append('<div class="debug" style="'+
            'height: auto;'+
            'width: auto;'+
            'max-height: 300px;'+
            'min-height: 20px;'+
            'min-width: 800px;'+
            'position: fixed;'+
            'top: 0px;'+
            'left: 0px;'+
            'z-index: 2000000000;'+
            'background: rgba(255, 255, 255, 0.9);'+
            'text-align: left;'+
            'padding: 10px;'+
            'line-height: 1.1;'+
            'overflow: auto;'+
            '"></div>');
        }
        var nowDate = new Date();   // 日付
        var date = nowDate.getFullYear()+"/"+
            (("0"+(nowDate.getMonth()+1)).slice(-2))+"/"+
            (("0"+nowDate.getDate()).slice(-2))+" "+
            (("0"+nowDate.getHours()).slice(-2))+":"+
            (("0"+nowDate.getMinutes()).slice(-2))+":"+
            (("0"+nowDate.getSeconds()).slice(-2));
        $('.debug').prepend(date+' '+inString+'<br>');
    }
