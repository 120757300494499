/*--------------------------------------
    サイドバーのランキングタブ操作
--------------------------------------*/

$(function() {
    //
    var targetTab = 'ul.rank-tab-list';
    var targetTabList = 'ul.rank-list';

    // 初期処理
    init();

    $(targetTab+' li > a').click(function() {
        // 押したタブの要素番号を基準にタブの表示状態を変更、タブに関連するコンテンツを表示
        var num = $(targetTab+' li > a').index(this);
        // タブ処理
        tabProc(num);

        return false;
    });

    /**
     * 初期処理
     */
    function init() {
        // タブ押下初期処理
        tabProc(0);
    }

    /**
     * タブ押下処理
     * 
     */
    function tabProc(inIndexNum) {
        // タブの初期表示を設定する
        $(targetTab+' li > a').removeClass('here');
        $(targetTab+' li:eq('+inIndexNum+') > a').addClass('here');

        // 対象コンテンツでtarget対象だけフェードイン表示
        $(targetTabList).removeClass('hide');
        $(targetTabList).hide();
        $(targetTabList+':eq('+inIndexNum+')').fadeIn();
    }

});